<template>
  <div class="container">
    <table-page dataName="black_lists"
                :search="search"
                ref="table"
                :tabList="tabList"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="fetchPacketBlacks">
      <template slot="button">
        <el-button class="mr-15"
                   type="primary"
                   @click="$refs.black.showModal($route.query.id)">添加黑名单</el-button>
        <FileUpload action="/api/mall/admin/envelope/black/list/with/yard/import"
                    class="mr-15"
                    @on-success="fileUploadSuccess"
                    :params="{activity_id:$route.query.id}"
                    title="导入黑名单" />
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="downloadBlack">下载黑名单模板</el-button>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.black.showModal($route.query.id,scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delPacketBlack(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <Black ref="black"
           @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUpload'
import Black from './components/SetBlack'
import { fetchPacketBlacks, delPacketBlack } from '@/api/market'
export default {
  name: 'PacketBlacks',
  data () {
    return {
      exportUrl: '',
      fetchPacketBlacks,
      search: [{
        key: 'search_type',
        type: 'select',
        value: '',
        name: '搜索类型',
        list: {
          value: 'TEL',
          label: '电话号码'
        }
      }, {
        key: 'tel',
        value: '',
        type: 'input',
        placeholder: '请输入关键词'
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'PENDING',
        label: '未领取'
      }, {
        value: 'RECEIVED',
        label: '已领取'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    Black
  },
  methods: {
    fileUploadSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          this.$alert('导入成功')
          this.$refs.table.fetch()
        } else {
          this.$alert(res.data.err_msg, '导入情况')
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    downloadBlack () {
      window.open('http://h.postpage.cn/data/envelope/%E7%BA%A2%E5%8C%85%E6%B4%BB%E5%8A%A8%E5%AF%BC%E5%85%A5%E9%BB%91%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx', '_blank')
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    addStore () {
      this.$router.push({
        name: 'SetStore'
      })
    },
    setStore (id) {
      this.$router.push({
        name: 'SetStore',
        query: {
          id: id
        }
      })
    },
    delPacketBlack (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delPacketBlack({
          activity_id: this.$route.query.id,
          black_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
